.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

/*
This is potentially a hack as I don't understand how to
make a nav item in a navbar grow to take as much space
as possible.
*/
.grow {
  flex-grow: 1;
  justify-content: flex-start;
}

/*
Display an entity block as a list of fields in a grid.
*/
.entity {
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
}

.entity p, .entity label {
  padding: 0;
  margin: 0;
}

.entity label {
  font-weight: bold;
}

.graph-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.graph-container .chart {
  display: flex;
  justify-content: center;
  width: 15em;
  height: 7.5em;
}

.graph-container div.chart {
  align-items: center;
}

@keyframes animateLoader {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.loader {
  padding: 1em;
  position: relative;
  border: none;
  margin: 0;
  text-align: center;
}
.loader:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0.2em;
  *margin: 0 0 -0.2em 0;
  background-image: linear-gradient(90deg, white, white, blue, white, white);
  background-size: 300% 100%;
  animation: animateLoader 2s infinite linear;
}

.inputrow {
  display: flex;
}

.top {
  align-items: flex-start;
}

.inputrow input.offset {
  margin-top: 0.4em;
}

.inputrow > *:nth-child(n+2) {
  margin-left: 1em;
}

.dateinput input.day {
  width: 3em;
}

.dateinput input.year {
  width: 5em;
}

.collapsible {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.collapsible .toggle {
  flex: none;
}

.collapsible .content {
  flex: 1;
}

.collapsible p.content {
  align-self: center;
  text-align: center;
}
